import React from "react";

export default function ErrorMessage(props) {
  function closeModal() {
    if (typeof window !== "undefined") {
      document.getElementById("backdrop2").style.display = "none";
      document.getElementById("exampleModal2").style.display = "none";
      document.getElementById(
        "exampleModal2"
      ).className += document
        .getElementById("exampleModal2")
        .className.replace("show", "");
    }
  }
  // Get the modal
  var modal =
    typeof window !== "undefined" && document.getElementById("exampleModal2");

  if (typeof window !== "undefined") {
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        closeModal();
      }
    };
  }
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content alert-danger">
            <div class="modal-header pr-3 pt-1 pb-0 border-0">
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="alert alert-danger pt-0" role="alert">
              <h4 class="alert-heading">Oops !!</h4>
              <p>
                Form Submition failed please retry to Submit your information. Or try after some time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-backdrop fade show"
        id="backdrop2"
        style={{ display: "none" }}
      ></div>
    </>
  );
}
