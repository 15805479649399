import React from "react";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Image from "gatsby-image";

const ProductTreatments = ({ treatments }) => {
  const intl = useIntl();

  if (treatments?.length === 0 || treatments === null) {
    return null;
  }
  return (
    <div className="container">
      <div className="section-title">
        <h2 className="sub-title">
          Linked {intl.formatMessage({ id: "treatments" })}
        </h2>
      </div>
      <div className="row">
        {treatments.map((treatment) => {
          const treatmentImage =
            intl.locale === "en"
              ? treatment?.image?.fluid
              : treatment?.imageFr?.fluid || treatment?.image?.fluid;
          const treatmentname =
            intl.locale === "en"
              ? treatment?.name
              : treatment?.name_fr || treatment?.name;

          return (
            <div className="col-lg-4 col-md-6">
              <Link to={`/treatments/${treatment.slug}`} className="link-btn">
                <div className="single-projects-box">
                  <div className="image">
                    <Image fluid={treatmentImage} alt="image" />
                  </div>

                  <div className="content">
                    <h3>{treatmentname}</h3>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductTreatments;
