import React from "react";
import { navigate } from "gatsby-link";
import SuccessMessage from './SuccessMessage'
import ErrorMessage from './ErrorMessage'


export default function Product(props) {
  const [state, setState] = React.useState({
    name:'',
    email:'',
    phone:'',
    message:''
  });
  const [submit, setSubmit] = React.useState(false);

  React.useEffect(() => {
    resetData()
  }, [])

  const resetData = () => {
    setSubmit(false);
    setState({
      name:'',
      email:'',
      phone:'',
      message:''
    })
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  function openSuccessModal() {
    if(typeof window !== 'undefined') {
    document.getElementById("backdrop1").style.display = "block";
    document.getElementById("exampleModal1").style.display = "block";
    document.getElementById("exampleModal1").className += "show";
  }
}

function openErrorModal() {
  if(typeof window !== 'undefined') {
  document.getElementById("backdrop2").style.display = "block";
  document.getElementById("exampleModal2").style.display = "block";
  document.getElementById("exampleModal2").className += "show";
}
}

  const SuccessData = () => {
    closeModal();
    openSuccessModal();
  }
  const ErrorData = () => {
    closeModal();
    openErrorModal();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
        product: props.productName,
      }),
    })
      .then((res) => res.status === 200 ? SuccessData() : ErrorData())
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  function closeModal() {
    if (typeof window !== "undefined") {
      document.getElementById("backdrop").style.display = "none";
      document.getElementById("exampleModal").style.display = "none";
      document.getElementById(
        "exampleModal"
      ).className += document
        .getElementById("exampleModal")
        .className.replace("show", "");
      resetData();
    }
  }
  // Get the modal
  var modal =
    typeof window !== "undefined" && document.getElementById("exampleModal");

  if (typeof window !== "undefined") {
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == modal) {
        closeModal();
      }
    };
  }
  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });
  return (
    <>
      <SuccessMessage/>
      <ErrorMessage />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Inquiry Form
              </h5>
              <button
                type="button"
                class="close"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="login-form">
              <form
                onSubmit={handleSubmit}
                method="post"
                action={props.currentUrl}
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="Product"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Product" />
                <div className="form-group">
                  <label>Name</label>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="name"
                    value={state.name}

                    className="form-control"
                    placeholder="please insert your name"
                  />
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="email"
                    value={state.email}

                    className="form-control"
                    placeholder="please insert your email address"
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="phone"
                    value={state.phone}
                    className="form-control"
                    placeholder="please insert your phone number"
                  />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    onChange={handleChange}
                    placeholder="please insert your message"
                    name="message"
                    className="form-control text-area-input"
                    rows="3"
                    cols="30"
                    value={state.message}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="hidden"
                    name="product"
                    value={props.productName}
                  />
                </div>

                <button type="submit" onClick={() => setSubmit(true)}>
                  Submit
                  {submit && <div
                    className="spinner-border spinner-border-sm mb-1 ml-3"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-backdrop fade show"
        id="backdrop"
        style={{ display: "none" }}
      ></div>
    </>
  );
}
