import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/App/Layout";
import NavbarTwo from "../components/App/NavbarTwo";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import Image from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappShareButton,
  TwitterShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  PinterestShareButton,
  PinterestIcon,
} from "react-share";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import FormDetail from "../components/Modal/FormDetail";
import ProductTreatments from "../components/ProductTreatments";
// typeof window !== 'undefined' && console.log('Window',window.location.href);

const SingleProduct = ({ data }) => {
  const datas = data.products;
  const product = datas.nodes[0];
  const intl = useIntl();
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <p className="d-flex justify-content-center">
          <img src={node.data.target.file.url} />
        </p>
      ),
    },
  };

  function openModal() {
    if (typeof window !== "undefined") {
      document.getElementById("backdrop").style.display = "block";
      document.getElementById("exampleModal").style.display = "block";
      document.getElementById("exampleModal").className += "show";
    }
  }
  let currentUrl = typeof window !== "undefined" && window?.location.href;
  let originName = typeof window !== "undefined" && window?.location.origin;
  let currentRoute = currentUrl?.toString().split(originName);
  const canonical = `${process.env.GATSBY_MAIN_URL}/${
    intl.locale === "en" ? "product" : "fr/produit"
  }/${product?.slug}`;
  const productName =
    intl.locale === "en" ? product?.name : product?.nameFr || product?.name;

  const productImage =
    intl.locale === "en"
      ? product?.image?.fluid
      : product?.imageFr?.fluid || product?.image?.fluid;
  const productImageFixed =
    intl.locale === "en"
      ? product?.image?.fixed.src
      : product?.imageFr?.fixed.src || product?.image?.fixed.src;
  return (
    <>
      <Layout
        title={productName}
        description={
          productName +
          " " +
          documentToPlainTextString(JSON.parse(product?.description.raw))
        }
        canonical={canonical}
        image={productImageFixed}
      >
        <NavbarTwo />
        <section className="blog-details-area ">
          <div className="container">
            <div className="row product-upper-container">
              <div className="col-lg-6 col-md-6 product-page">
                <Image className="product-image" fluid={productImage} />
              </div>
              <div className="col-lg-6 col-md-6 product-page">
                <PageBanner
                  homePageText={intl.formatMessage({ id: "home" })}
                  homePageUrl="/"
                  treatMentPageText={intl.formatMessage({ id: "products" })}
                  treatMentPageUrl={`/products`}
                  activePageText={productName}
                />
                <h2>{productName}</h2>
                <div onClick={openModal} className="btn btn-primary-color mb-3">
                  {intl.formatMessage({ id: "inquiry" })}
                </div>
                <FormDetail
                  currentUrl={currentRoute[1]}
                  productName={productName}
                />
                <div className="product_meta d-flex  mt-3">
                  <p className="category-title">
                    {intl.formatMessage({ id: "category" })} :{" "}
                  </p>
                  {product?.category?.map((category) => {
                    const productCategoryName =
                      intl.locale === "en"
                        ? category?.name
                        : category?.name_fr || category?.name;
                    return (
                      <Link
                        to={`/${
                          intl.locale === "en"
                            ? "product-category"
                            : "categorie-produit"
                        }/${category.slug}`}
                      >
                        <p className="category-name ml-2">
                          {" "}
                          {productCategoryName}
                        </p>
                      </Link>
                    );
                  })}
                </div>

                <div className="social-media-icons-product">
                  <FacebookShareButton
                    url={typeof window !== "undefined" && window?.location.href}
                    quote={"MedArt Technology Laser Devices Manufacturer"}
                    hashtag="#medart"
                    // className={classes.socialMediaButton}
                  >
                    <FacebookIcon round size={32} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={typeof window !== "undefined" && window?.location.href}
                    title={"MedArt Technology Laser Devices Manufacturer"}
                    hashtag="#medart"
                    // className={classes.socialMediaButton}
                  >
                    <TwitterIcon round size={32} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={
                      typeof window !== "undefined" &&
                      typeof window !== "undefined" &&
                      window?.location.href
                    }
                    title={"MedArt Technology Laser Devices Manufacturer"}
                    separator=":: "
                    // className={classes.socialMediaButton}
                  >
                    <WhatsappIcon round size={32} />
                  </WhatsappShareButton>
                  <EmailShareButton
                    subject={productName}
                    url={typeof window !== "undefined" && window.location.href}
                    title={"MedArt Technology Laser Devices Manufacturer"}
                    // className={classes.socialMediaButton}
                  >
                    <EmailIcon round size={32} />
                  </EmailShareButton>
                  <PinterestShareButton
                    url={typeof window !== "undefined" && window.location.href}
                    title={"MedArt Technology Laser Devices Manufacturer"}
                    // className={classes.socialMediaButton}
                  >
                    <PinterestIcon round size={32} />
                  </PinterestShareButton>
                </div>
              </div>
              <div className="container-fluid">
                <div className=" ptb-100 product_meta">
                  <h4 className="d-block">
                    <u>{intl.formatMessage({ id: "description" })}</u>
                  </h4>
                  <div className="courses-details-desc">
                    {intl.locale === "en"
                      ? renderRichText(product?.description, options)
                      : product?.descriptionFr
                      ? renderRichText(product?.descriptionFr, options)
                      : renderRichText(product?.description, options)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductTreatments treatments={product.treatment} />
        </section>
        <Footer />
      </Layout>
    </>
  );
};

export const query = graphql`
  query GetSingleProducts($slug: String) {
    products: allContentfulProduct(filter: { slug: { eq: $slug } }) {
      nodes {
        name
        slug
        nameFr
        category {
          slug
          name
          name_fr
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
            src
          }
          fixed {
            src
          }
        }
        imageFr {
          fluid {
            ...GatsbyContentfulFluid
            src
          }
          fixed {
            src
          }
        }
        description {
          raw
          references {
            contentful_id
            __typename
            file {
              url
            }
          }
        }
        descriptionFr {
          raw
          references {
            contentful_id
            __typename
            file {
              url
            }
          }
        }
        treatment {
          imageFr {
            fluid(quality: 30) {
              ...GatsbyContentfulFluid
            }
          }
          image {
            fluid(quality: 30) {
              ...GatsbyContentfulFluid
            }
          }
          name
          name_fr
          slug
        }
      }
    }
  }
`;

export default SingleProduct;
